.generateEncoding {
    padding-top: 50px;
}
.home .ant-row{
    /* height: 40px; */
    padding-bottom: 3vh;

}
.home .ant-col{
    display: flex;
}

.home .ant-flex{
    align-items: center;
}
.SNClass button {
   width: 90px;
}
.SNShow .ant-card-body {
    padding: 18px 10px;
}
.codeImgShow .ant-card-body {
    /* padding: 10px 10px; */
    padding: 0;
}
.codeImg {
   width: 310px;
   height: 72px;
}

.codeChild{
    height: auto;
    min-height: 64px;
    margin: 4px;
    display: flex;
    flex-direction: column;
}
.snInformation {
    width: 360px !important;
}

.snInformation .ant-modal-content {
    padding: 20px 5px ;
}
.snInformation p span {
    display: inline-block;
    min-width: 170px;
}
.printImg {
    display: none;
}
@media print {
    .site-layout, .non-printable, .CodeClass {
        display: none;
    }
    .codeImgShow{
        border: none !important;
    }

    .print-only {
        display: none;
    }
    .printImg {
        display: block;
        width: 440px;
        height: 72px;
    }
}
  .warning{
    width: 30px;
    height: 30px;
    display: inline-block;
    background: url("../../Image/warning.png");
    background-repeat: no-repeat;
    background-size: 100% 100%;
  }
  .error{
    width: 30px;
    height: 30px;
    display: inline-block;
    background: url("../../Image/error.png");
    background-repeat: no-repeat;
    background-size: 100% 100%;
  }

