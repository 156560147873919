.home .ant-row{
    /* height: 40px; */
    padding-bottom: 3vh;

}
.home .ant-col{
    display: flex;
}

.home .ant-flex{
    align-items: center;
}
.ant-tabs-ink-bar {
    display: none;
}
.ant-tabs-tab-btn h3{
    padding-top: 20px;
}
.ant-layout-header {
    background:rgb(211, 227, 253);
}
.ant-tabs-card >.ant-tabs-nav .ant-tabs-tab{
    border: none ;
    padding: 1px 16px 0px;
}
.ant-layout-header {
    padding: 0 0 0 50px ;
}
@media print {
    
    .non-printable {
        display: none !important;
    }
    
}
/* .ant-tabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    background: white;
    border-bottom: 1px solid white;
} */
/* .ant-tabs-tab .ant-tabs-tab-active{
    background: white !important;
} */

